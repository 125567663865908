import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import window from 'global/window'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'

const mapContent = (oldObj) => {
  let newObj = {
    'it': [],
    'en': []
  };

  for (let p = 0; p < oldObj.length; p++) {
    newObj[oldObj[p].node.node_locale].push(oldObj[p].node);
  }
  
  newObj['it'].sort((a, b) => (a.order > b.order) ? 1 : -1);
  newObj['en'].sort((a, b) => (a.order > b.order) ? 1 : -1);
  
  for (const loc in newObj) {
    for (let n = 0; n < newObj[loc].length; n++) {
      newObj[loc][n].navName = newObj['it'][n].name;
      if (newObj[loc][n].subPages) {
        for (let s = 0; s < newObj[loc][n].subPages.length; s++) {
          newObj[loc][n].subPages[s].navName = newObj['it'][n].subPages[s].name;
        }
      }
    }
  }

  return newObj;
}

class notFound extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      pages: {},
      contactQuery: get(this, 'props.data.allContentfulContact').edges,
      pagesQuery: get(this, 'props.data.allContentfulPage').edges
    };
  }

  componentWillMount() {
    const { pagesQuery, contactQuery } = this.state;
    
    this.setState({
      pages: mapContent(pagesQuery),
      contact: mapContent(contactQuery)
    });
  }

  render() {
    const { pages, contact } = this.state;

    const route = "404"

    const locale = 
      (window.location
      && window.location.href.search("language=") !== -1
      && window.location.href.substr(window.location.href.search("language=") + 9, window.location.href.length))
      || "";
    const defaultLocale = 'it';

    let newPage;
    for (let p = 0; p < pages[locale || defaultLocale].length; p++) {
      if (route === pages[locale || defaultLocale][p].navName) {
        newPage = pages[locale || defaultLocale][p];
      }
    }

    let newContact = contact[locale || defaultLocale][0];
    
    return (
      <Layout route={route} pages={pages[locale || defaultLocale]} locale={locale} page={newPage}>
        <Helmet>
          <title>Centro De Graaff</title>
          <meta name="description" content="Studio Centro De Graaff Bardolino Lazise Dentist Italy info@degraaff.it" />
          <meta name="author" content="www.tomruys.com" />
          <meta property="og:title" content="Centro De Graaff" />
          <meta property="og:image" content="http://www.centrodegraaff.com/static/centrodegraafflogo3-32794effd44aedc3fa9c7676d16926d4.jpg" />
          <meta property="og:description" content="Studio Centro De Graaff Bardolino Lazise Dentist Italy info@degraaff.it" />
        </Helmet>
        <Header page={newPage} contact={newContact} setLocale={locale || defaultLocale} />
        <Footer contact={newContact} setLocale={locale || defaultLocale} />
      </Layout>
    )
  }
}

export default notFound

export const pageQuery = graphql`
  query notFoundQuery {
    allContentfulContact {
      edges {
        node {
          node_locale
          title
          addresses {
            title
            street
            tel
            email
          }
          headerTitle
        }
      }
    }
    allContentfulLogo {
      edges {
        node {
          title
          logo: logo {
            sizes(
              maxWidth: 1500
              maxHeight: 500
            ) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          node_locale
          name
          title {
            json
          }
          showInMenu
          images {
            sizes(maxWidth: 2000, maxHeight: 1000) {
             ...GatsbyContentfulSizes_withWebp
            }
          }
          description {
            json
          }
          subPages {
            name
            title {
              json
            }
            description {
              json
            }
            images {
              sizes(maxWidth: 2000, maxHeight: 1000) {
               ...GatsbyContentfulSizes_withWebp
              }
            }
            announcement
            order
          }
          announcement
          order
        }
      }
    }
  }
`
//
// <Hero data={author.node} />
// <div className="wrapper">
//   <h2 className="section-headline">Recent articles</h2>
//   <ul className="article-list">
//     {posts.map(({ node }) => {
//       return (
//         <li key={node.slug}>
//           <ArticlePreview article={node} />
//         </li>
//       )
//     })}
//   </ul>
// </div>
